export default defineI18nConfig(() => ({
  legacy: false,
  locale: 'en',
  messages: {
    en: {
      ERROR: {
        AUTH: {
          PASSWORD_OR_EMAIL_INCORRECT:
            'Email or password is incorrect. Please check your input.',
          USER_ALREADY_EXISTS:
            'This username is already taken. Please choose another one.',
        },
        CRAWL: {
          NO_CONTENT_EXTRACTED:
            "We couldn't extract the contents of the webpage. Please try again later. Your credits have been refunded.",
          INVALID_URL: 'The provided URL is invalid. Please check your input.',
        },
        CREDITS_INSUFFICIENT:
          'You do not have enough credits. Please top up your balance.',
        DB: {
          FIND: 'The entry could not be found. Please try again later.',
          MISSING_SEED:
            'The entry could not be deleted. Please try again later.',
          SAVE: 'The entry could not be saved. Please try again later.',
          UPDATE:
            'An error occurred while updating the entry. Please try again later.',
        },
        FILE_UPLOAD: {
          GENERIC: 'The file could not be uploaded. Please try again later.',
          MAX_SIZE_EXCEEDED: 'The file is too large (max. 5MB).',
        },
        SCAN: {
          CANCELED:
            'The scan was cancelled. Press "new scan" to start a new scan.',
        },
        GENERIC: 'An error occurred. Please try again later.',
        INVITE: {
          ALREADY_ASSIGNED_TO_ORG:
            'The user is already assigned to another organization. Please contact our support for a transfer.',
          ALREADY_INVITED: 'The user has already been invited.',
          NOT_FOUND: 'The user could not be found. Please invite them again.',
        },
        LINKEDIN: {
          NO_PROFILE_FOUND:
            'No LinkedIn profile found. Please check your input.',
          NO_LEADS_FOUND: 'No matching leads found. Please try again later.',
        },
        OPENAI: {
          GENERIC:
            'The AI is currently overloaded. Please try again later. Your credits have been refunded.',
        },
        PROFILE: {
          CREATE: 'The profile could not be created. Please try again later.',
        },
        PUPETEER: {
          INVALID_URL: 'Please check the entered URL and try again.',
        },
        RECAPTCHA: 'Recaptcha verification failed. Please try again later.',
        STRIPE: {
          CANCEL_SUBSCRIPTION:
            'Error while canceling the subscription. Please try again later.',
          CANT_VERIFY_SIGNATURE:
            'Stripe signature could not be verified. Please try again.',
          GENERIC: 'An error occurred. Please try again later.',
          TAX_ERROR: 'Invalid VAT ID. Please check your input.',
          USER_UPDATE:
            'Error updating user information. Please try again later.',
        },
        PERMISSION: {
          ONLY_ADMIN:
            'This action is only allowed for admins or the super admin.',
          ONLY_SUPERADMIN: 'This action is only allowed for the super admin.',
        },
      },
      LABEL: {
        MODIFICATOR: {
          CASUAL: 'Casual addressing',
          FORMAL: 'Formal addressing',
          INFORMAL: 'Informal',
          LESS_VERBOSE: 'Reduce customer references',
          RESPECTFUL: 'Respectful',
          SHORTENED: 'Shorten text',
          STRONGLY_SHORTENED: 'Greatly shorten text',
          TRANSLATE: 'Translate',
          VERBOSE: 'Elaborate on customer references',
        },
      },
    },
    de: {
      ERROR: {
        AUTH: {
          PASSWORD_OR_EMAIL_INCORRECT:
            'E-Mail oder Passwort ist falsch. Bitte überprüfe deine Eingaben.',
          USER_ALREADY_EXISTS:
            'Dieser Benutzername ist bereits vergeben. Bitte wähle einen anderen.',
        },
        CRAWL: {
          NO_CONTENT_EXTRACTED:
            'Wir konnten die Inhalte der Webseite leider nicht extrahieren. Bitte versuche es später erneut. Deine Credits wurden gutgeschrieben.',
          INVALID_URL:
            'Die angegebene URL ist ungültig. Bitte überprüfe deine Eingabe.',
        },
        CREDITS_INSUFFICIENT:
          'Du hast nicht genügend Credits. Bitte lade dein Guthaben auf.',
        DB: {
          FIND: 'Der Eintrag könnte nicht gefunden werden. Bitte versuche es später erneut.',
          MISSING_SEED:
            'Der Eintrag konnte nicht gelöscht werden. Bitte versuche es später erneut.',
          SAVE: 'Der Eintrag konnte nicht gespeichert werden. Bitte versuche es später erneut.',
          UPDATE:
            'Es ist ein Fehler bei der Aktualisierung aufgetreten. Bitte versuche es später erneut.',
        },
        FILE_UPLOAD: {
          GENERIC:
            'Die Datei konnte nicht hochgeladen werden. Bitte versuche es später erneut.',
          MAX_SIZE_EXCEEDED: 'Die Datei ist zu groß (max. 5MB).',
        },
        SCAN: {
          CANCELED:
            'Sie haben den Scan abgebrochen, klicken Sie auf „neuer Scan“ um einen neuen Scan auszuführen.',
        },
        GENERIC:
          'Es ist ein Fehler aufgetreten. Bitte versuche es später erneut.',
        INVITE: {
          ALREADY_ASSIGNED_TO_ORG:
            'Der Benutzer gehört bereits zu einer anderen Organisation. Bitte kontaktiere unseren Support für einen Wechsel.',
          ALREADY_INVITED: 'Der Benutzer wurde bereits eingeladen.',
          NOT_FOUND:
            'Der Benutzer konnte nicht gefunden werden. Bitte lade ihn erneut ein.',
        },
        LINKEDIN: {
          NO_PROFILE_FOUND:
            'Kein LinkedIn-Profil gefunden. Bitte überprüfe deine Eingabe.',
          NO_LEADS_FOUND:
            'Keine passenden Leads gefunden. Bitte versuche es später erneut.',
        },
        OPENAI: {
          GENERIC:
            'Die KI kann sich momentan nicht konzentrieren. Bitte versuche es später erneut. Deine Credits wurden wieder gutgeschrieben.',
        },
        PROFILE: {
          CREATE:
            'Das Profil konnte nicht erstellt werden. Bitte versuche es später erneut.',
        },
        PUPETEER: {
          INVALID_URL:
            'Bitte überprüfe die eingegebene URL und versuche es erneut.',
        },
        RECAPTCHA:
          'Recaptcha-Verifizierung fehlgeschlagen. Bitte versuche es später erneut.',
        STRIPE: {
          CANCEL_SUBSCRIPTION:
            'Fehler bei der Kündigung des Abonnements. Bitte versuche es später erneut.',
          CANT_VERIFY_SIGNATURE:
            'Stripe-Signatur konnte nicht verifiziert werden. Bitte versuche es später erneut.',
          GENERIC:
            'Ein Fehler ist aufgetreten. Bitte versuche es später erneut.',
          TAX_ERROR: 'Ungültige USt-Id. Bitte überprüfe deine Eingabe.',
          USER_UPDATE:
            'Fehler bei der Aktualisierung der Benutzerinformationen. Bitte versuche es später erneut.',
        },
        PERMISSION: {
          ONLY_ADMIN:
            'Diese Aktion ist nur für Admins oder den Superadmin erlaubt.',
          ONLY_SUPERADMIN: 'Diese Aktion ist nur für den Superadmin erlaubt.',
        },
      },
      LABEL: {
        MODIFICATOR: {
          CASUAL: 'Ansprechpartner Duzen',
          FORMAL: 'Siezen',
          INFORMAL: 'Informell',
          LESS_VERBOSE: 'Kundenbezug reduzieren',
          RESPECTFUL: 'Formell',
          SHORTENED: 'Text kürzen',
          STRONGLY_SHORTENED: 'Text stark kürzen',
          TRANSLATE: 'Übersetzen',
          VERBOSE: 'Mehr auf Kunden eingehen',
        },
      },
    },
  },
}));
